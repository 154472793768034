import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';

import LogoImg  from '../../assets/img/logo.svg';
import { changePasswordService } from '../../services/authService';
import { changePasswordAction } from '../../_action/authAction';
import { loginService } from '../../services/authService';
import { loginAction } from '../../_action/authAction';
import { Loader } from '../../components/util/Svg';


const ChangePassword = props => {
    const [loader, setLoader] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordErr, setPasswordErr] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [oldPasswordErr, setOldPasswordErr] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');


    const handleValidate = () => {
        let validate = true;
        if(oldPassword == ''){
            setOldPasswordErr('Old password is required');
            validate = false
        }

        if(password == ''){
            setPasswordErr('New Password is required');
            validate = false
        }
        if(password.length < 8)
        {
            setPasswordErr('Password must be atleast 8 characters long.');
            validate = false
        }
        return validate;
    }


    const handleSubmit = e => {
        setErrorMessage('');
        setSuccessMessage('');
        e.preventDefault();

        if(handleValidate()){
            let params = {
                password,
                oldPassword
            }

            setLoader(true);

            changePasswordService(params).then(res => {
                if(res.status){
                    let paramsLogin = {
                        email: res.data.user.email,
                        password: password
                    }
        
                    //setLoader(true);
        
                    loginService(paramsLogin).then(res => {
                        setLoader(false);
                        if(res.status){
                            setLoader(false);
                            localStorage.setItem('accessToken', res.data.accessToken);
                            localStorage.setItem('auth', JSON.stringify(res.data.adminProfile));
                            setSuccessMessage("Successfully Changed Password");
                        }
                        else{
                            setLoader(false);
                            console.log(res.data);
                            setErrorMessage(res.data);
                        }
                    })
                }
                else{
                    setLoader(false);
                    console.log(res.data);
                    setErrorMessage(res.data);
                }
            })
        }

    }


    return(
        <div className="d-flex justify-content-between align-items-center auth_wrapper">
            {loader ? <div className="loader_lg"><Loader /></div> : ''}
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-6 card p-5">
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label>Old Password</label>
                                    <input
                                        className={`form-control ${oldPasswordErr ? 'is-invalid' : ''} rounded-pill`}
                                        type="password"
                                        value={oldPassword}
                                        onChange={e => (setOldPassword(e.target.value), setOldPasswordErr(''))}
                                        placeholder="Enter your old password" />

                                        {oldPasswordErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{oldPasswordErr}</Tooltip>}>
                                                    <span className="error_tootltip" />
                                                </OverlayTrigger> : ''}
                                </div>
                                <div className="form-group">
                                    <label>New Password</label>
                                    <input
                                        className={`form-control ${passwordErr ? 'is-invalid' : ''} rounded-pill`}
                                        type="password"
                                        value={password}
                                        onChange={e => (setPassword(e.target.value), setPasswordErr(''))}
                                        placeholder="Enter your new password" />

                                        {passwordErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{passwordErr}</Tooltip>}>
                                                    <span className="error_tootltip" />
                                                </OverlayTrigger> : ''}
                                </div>

                                <div className="text-center mt-3">
                                    {errorMessage ? <div className="error"> <h6>{errorMessage}</h6> </div>:successMessage && <div className="success"> <h6>{successMessage}</h6> </div>}
                                     {!errorMessage  && !successMessage ? <br/>:''}
                                    <button type="submit" className="btn btn-primary rounded-pill pl-5 pr-5">ChangePassword</button> 
                                </div>
                            </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    return {
        state
    };
}
  
const mapDispatchToProps = dispatch => ({
    changePasswordAction: params => dispatch(changePasswordAction(params)),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);