import { combineReducers } from 'redux';
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';


import uiReducer from './_reducer/uiReducer';
import authReducer from './_reducer/authReducer';

const rootReducer = combineReducers({
    ui: uiReducer,
    auth: authReducer,
});


const store = createStore(
    rootReducer,
    applyMiddleware(
        thunkMiddleware
    )
);

export default store;