import axios from 'axios';
import API from '../config/api';
import { resHandle } from '../helper';


export const backgroundListService = params => axios.get(API.BACKGROUND_LIST + params).then(res => {
    let { status, data } = resHandle(res);
    return {status, data};
});

export const createBackgroundService = (query, params) => axios.post(API.CREATE_BACKGROUND + query, params).then(res => {
    let { status, data } = resHandle(res);
    return {status, data};
});

export const deleteBackgroundService = params => axios.post(API.DELETE_BACKGROUND + params).then(res => {
    let { status, data } = resHandle(res);
    return {status, data};
});