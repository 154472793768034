import React, { useEffect, useState } from 'react';
import { Dropdown, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';

import {
    luxListService,
    createLuxService,
    deleteLuxService,
    updateLuxService
} from '../../services/luxService';
import { Loader } from '../../components/util/Svg';
import { isColor } from '../../helper';
import Breadcrumb from '../../components/Breadcrumb';


const Lux = () => {
    const [list, setList] = useState([]);
    const [loader, setLoader] = useState(true);
    const [createModal, setCreateModal] = useState(false);
    const [color, setColor] = useState('');
    const [colorErr, setColorErr] = useState('');
    const [file, setFile] = useState('');
    const [fileErr, setFileErr] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);
    const [currentId, setCurrentId] = useState('');


    const breadcrumb = [
        {link: '', linkText: 'Lux'}
    ]


    const handleClose = () => {
        setCreateModal(false);
        setColor('');
        setFile('');
        setColorErr('');
        setFileErr('');
        setCurrentId('');

    }


    const getList = () => {
        let params = ``;
        luxListService(params).then(res => {
            setLoader(false);
            if(res.status){
                setList(res.data.result)
            }
        })
    }


    useEffect(() => {
        getList();
    }, [])



    const handleFileChange = e => {
        setFile(e.target.files[0])
    }


    const handleValidate = () => {
        let validate = true;
        if(color == ''){
            setColorErr('Color is required');
            validate = false
        }else if(!isColor(color.trim())){
            setColorErr('Color is invalid');
        }

        if(file == ''){
            setFileErr('File is required');
        }

        return validate;
    }


    const handleSubmit = e => {
        e.preventDefault();
        if(handleValidate()){
            setIsDisabled(true);
            let params = new FormData();
            if(currentId){
                params.append('luxId', currentId);
            }
            params.append('lux', file);
            params.append('color', color);

            let _service = currentId ? updateLuxService : createLuxService;

            _service(params).then(res => {
                setIsDisabled(false);
                if(res.status){
                    handleClose();
                    getList();
                }
            })
        }
        
    }


    const handleDelete = luxId => {
        let tempList = list.filter(i => i._id != luxId);
        setList(tempList);
        let params = { luxId }
        deleteLuxService(params)
    }


    const handleEdit = item => {
        setCreateModal(true);
        setColor(item.color);
        setCurrentId(item._id);
    }



    return(
        <div className="page_wrapper">
            <div className="search_bar">
                <i className="fa fa-search" />
                <input type="text" className="form-control" placeholder="Search" />
                <button className="btn btn-primary btn-sm">Search</button>
            </div>

            <Breadcrumb breadcrumb={breadcrumb} />

            <div className="d-flex justify-content-between align-content-center page_header">
                <h2>Lux List</h2>
                <span className="btn btn-primary" onClick={() => setCreateModal(true)}>Create</span>
            </div>


            <div className="table-responsive cm_card p-3 cm_table_min_height">
                {loader ? <div className="loader_lg"><Loader /></div> : ''}
                <table className="table user-table table-hover align-items-center">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Color</th>
                            <th>Created</th>
                            <th>File</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((item, i) => (
                            <tr key={item._id}>
                                <td>{i + 1}</td>
                                <td><span className="color_box" style={{background: item.color}} />{item.color}</td>
                                <td>{moment(item.created).format('DD MMM, YYYY')}</td>
                                <td><a href={item.lux} target="_blank"><i className="fa fa-file" /></a></td>
                                <td>
                                    <Dropdown alignRight>
                                        <Dropdown.Toggle className="no_btn"><i className="ellipsis-h" /></Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item href="javascript:void(0)" onClick={() => handleEdit(item)}>Edit</Dropdown.Item>
                                            <Dropdown.Item href="javascript:void(0)" onClick={() => handleDelete(item._id)}>Delete</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>



            <Modal
                show={createModal}
                onHide={handleClose}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Lux</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label>color</label>
                            <input
                                className={`form-control ${colorErr ? 'is-invalid' : ''} rounded-pill`}
                                type="text"
                                value={color}
                                onChange={e => (setColor(e.target.value), setColorErr(''))}
                                placeholder="Enter color name" />

                                {colorErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{colorErr}</Tooltip>}>
                                            <span className="error_tootltip" />
                                        </OverlayTrigger> : ''}
                        </div>


                        <div className="form-group">
                            <label>File</label>
                            <input
                                className={`form-control ${fileErr ? 'is-invalid' : ''} rounded-pill`}
                                type="file"
                                accept="application/json"
                                value=""
                                onChange={handleFileChange}
                                placeholder="Select Lux JSON file" />

                                {fileErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{fileErr}</Tooltip>}>
                                            <span className="error_tootltip" />
                                        </OverlayTrigger> : ''}
                        </div>

                        <div className="text-center mt-3">
                            <button disabled={isDisabled} type="submit" className="btn btn-primary rounded-pill pl-5 pr-5">Submit</button> 
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default Lux;