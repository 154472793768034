import React from 'react';
import { NavLink } from 'react-router-dom';

import LogoImg  from '../../assets/img/logo.svg';


const Sidebar = () => {
    return(
        <div className="cm_sidebar">
            <h3><img src={LogoImg} /></h3>
            
            <ul>
                <li><NavLink exact to="/"><i className="fa fa-home" /> <span className="menu_text">Dashboard</span></NavLink></li>
                <li><NavLink exact to="/users"><i className="fa fa-users" /> <span className="menu_text">Users</span></NavLink></li>
                <li><NavLink exact to="/lux"><i className="fa fa-file" /> <span className="menu_text">Lux</span></NavLink></li>
                <li><NavLink exact to="/background"><i className="fa fa-picture-o" /> <span className="menu_text">Background</span></NavLink></li>
                <li><NavLink exact to="/feedbacks"><i className="fa fa-comment" /> <span className="menu_text">Feedbacks</span></NavLink></li>
            </ul>
        </div>
    )
}

export default Sidebar;