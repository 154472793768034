import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import LogoImg  from '../../assets/img/logo.svg';
import { forgotPassword } from '../../services/authService';


const ForgotPassword = () => {
    const [loader, setLoader] = useState('');
    const [email, setEmail] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [sentEmail, setSentEmail] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');


    const handleValidate = () => {
        let validate = true;
        if(email == ''){
            setEmailErr('Email is required');
            validate = false
        }

        return validate;
    }


    const handleSubmit = e => {
        console.log("here:", email);
        e.preventDefault();

    if(handleValidate()){
        setLoader(true);
        let params = {
            email
        }
        forgotPassword(params).then(res => {
            setLoader(false);
            console.log("forgot::::", res);
            if(res.status){
                
                setSentEmail(true);

            }
            else{
                setLoader(false);
                console.log(res.data);
                setErrorMessage(res.data);
            }
        })
    }

    }


    return(
        <div className="d-flex justify-content-between align-items-center auth_wrapper">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-6 card p-5">
                        <img src={LogoImg} alt="Whoop" className="auth_logo" />
                        {sentEmail == false?
                         <form onSubmit={handleSubmit}>
                         <div className="form-group">
                             <label>Email</label>
                             <input
                                 className={`form-control ${emailErr ? 'is-invalid' : ''} rounded-pill`}
                                 type="email"
                                 value={email}
                                 onChange={e => (setEmail(e.target.value), setEmailErr(''))}
                                 placeholder="Enter your email" />

                                 {emailErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{emailErr}</Tooltip>}>
                                             <span className="error_tootltip" />
                                         </OverlayTrigger> : ''}

                                 <small>
                                     <Link to="/login">Back to login</Link>
                                 </small>
                         </div>

                         <div className="text-center mt-3">
                            {errorMessage && <div className="error"> <h6>{errorMessage}</h6> </div>}
                             <button type="submit" className="btn btn-primary rounded-pill pl-5 pr-5">Submit</button> 
                         </div>
                     </form>
                    :
                    <div className="row justify-content-center">
                        <h6>Email has been sent successfully, please check your email.</h6>
                    </div>}
                            </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword;