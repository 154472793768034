import * as actionType from '../actionsTypes';


let auth = JSON.parse(localStorage.getItem('auth'));
const initialState = auth ? { loggedIn: true, adminProfile: auth } : {};


const authReducer = (state = initialState, action) => {

    switch (action.type) {

        case actionType.LOGIN_SUCCESS:
            return {
                ...state,
                loggedIn: true,
                loading: false,
                userProfile: action.payload
            };

            case actionType.LOGOUT_SUCCESS:
                return {};

        default:
            return state
    }
}


export default authReducer;