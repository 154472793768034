import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';

import LogoImg  from '../../assets/img/logo.svg';
import { resetPasswordService } from '../../services/authService';
import { resetPasswordAction } from '../../_action/authAction';
import { Loader } from '../../components/util/Svg';


const ResetPassword = props => {
    const [loader, setLoader] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [password, setPassword] = useState('');
    const [passwordErr, setPasswordErr] = useState('');
    const [confirmPasswordErr, setConfirmPasswordErr] = useState('');
    const [resetPassword, setResetPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    //let token = props.location.pathname.split("/").pop();

    const handleValidate = () => {
        let validate = true;
        if(confirmPassword == ''){
            setConfirmPasswordErr('Confirm password is required');
            validate = false
        }

        if(password == ''){
            setPasswordErr('Password is required');
            validate = false
        }

        if(confirmPassword != password){
            setConfirmPasswordErr('Password and confirm password do not match');
            validate = false
        }
        if(password.length <8)
        {
            setPasswordErr('Password must contain atleast 8 letters');
            validate = false
        }

        return validate;
    }


    const handleSubmit = e => {
        e.preventDefault();
        let token = props.match.params.token;
        if(handleValidate()){
            let params = {
                accessToken: token,
                newPassword: password
            }

            setLoader(true);
            
            resetPasswordService(params).then(res => {
                setLoader(false);
                if(res.status){
                    setResetPassword(true)
                    // localStorage.setItem('accessToken', res.data.accessToken);
                    // localStorage.setItem('auth', JSON.stringify(res.data.adminProfile));
                    // props.resetPasswordAction(res.data.adminProfile);
                }
                else{
                    setLoader(false);
                    console.log(res.data);
                    setErrorMessage(res.data);
                }
            })
        }

    }


    return(
        <div className="d-flex justify-content-between align-items-center auth_wrapper">
            {loader ? <div className="loader_lg"><Loader /></div> : ''}
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-6 card p-5">
                        <img src={LogoImg} alt="Whoop" className="auth_logo" />
                        {resetPassword == false ?
                         <form onSubmit={handleSubmit}>
                         <div className="form-group">
                             <label>New Password</label>
                             <input
                                 className={`form-control ${passwordErr ? 'is-invalid' : ''} rounded-pill`}
                                 type="password"
                                 value={password}
                                 onChange={e => (setPassword(e.target.value), setPasswordErr(''))}
                                 placeholder="Enter a new password" />

                                 {passwordErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{passwordErr}</Tooltip>}>
                                             <span className="error_tootltip" />
                                         </OverlayTrigger> : ''}
                         </div>
                         <div className="form-group">
                             <label>Confirm Password</label>
                             <input
                                 className={`form-control ${confirmPasswordErr ? 'is-invalid' : ''} rounded-pill`}
                                 type="password"
                                 value={confirmPassword}
                                 onChange={e => (setConfirmPassword(e.target.value), setConfirmPasswordErr(''))}
                                 placeholder="Repeat your new password" />
                             
                             {confirmPasswordErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{confirmPasswordErr}</Tooltip>}>
                                         <span className="error_tootltip" />
                                     </OverlayTrigger> : ''}
                         </div>

                         <div className="text-center mt-3">
                            {errorMessage && <div className="error"> <h6>{errorMessage}</h6> </div>}
                             <button type="submit" className="btn btn-primary rounded-pill pl-5 pr-5">Reset Password</button> 
                         </div>
                     </form>
            
                      :
                      <>
                      <h6>Successfully changed password, kindly click on the button to login.</h6> 
                      <br/>

                      <Link to="/login" className="btn btn-primary rounded-pill pl-5 pr-5">Go to login page</Link>
                       </>}
                        </div>
                </div>
            </div>
        </div>
    )
}

/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    return {
        state
    };
}
  
const mapDispatchToProps = dispatch => ({
    resetPasswordAction: params => dispatch(resetPasswordAction(params)),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);