

const initialState = {}


const uiReducer = (state = initialState, action) => {

    switch (action.type) {


        default: return state;
    }
}



export default uiReducer;