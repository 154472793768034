import setting from './settings';

export default (() => {
  return {
    'AUTH': setting.api.AUTH,
    'ADMIN_LOGIN': setting.api.url + 'admin/login',
    'ADMIN_LOGOUT': setting.api.url + 'admin/logout',
    'FORGOT_PASSWORD': setting.api.url + 'admin/forgotPassword',
    'RESET_PASSWORD': setting.api.url + 'admin/resetPassword',
    'USER_LIST': setting.api.url + 'admin/user/usersList',
    'CAR_LIST': setting.api.url + 'admin/car/carsList',
    'LUX_LIST': setting.api.url + 'lux/viewAll',
    'CREATE_LUX': setting.api.url + 'lux/upload',
    'DELETE_LUX': setting.api.url + 'lux/delete',
    'UPDATE_LUX': setting.api.url + 'lux/update',
    'BACKGROUND_LIST': setting.api.url + 'admin/background/viewAll',
    'CREATE_BACKGROUND': setting.api.url + 'admin/background/upload',
    'DELETE_BACKGROUND': setting.api.url + 'admin/background/deleteOne',
    'GET_FEEDBACKS': setting.api.url + 'admin/feedbacks/view',
    'GET_DASHBOARD': setting.api.url + 'admin/getCounts',
    'ADMIN_CHANGE_PASS':  setting.api.url + 'admin/update',
  }
})()