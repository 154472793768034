import React, { useEffect, useState } from 'react';

import { getDashboardService } from '../../services/otherService';


const Dashboard = () => {
    const [data, setData] = useState('');

    const getData = () => {
        getDashboardService().then(res => {
            if(res.status){
                setData(res.data.counts);
            }
        })
    }


    useEffect(() => {
        getData();
    }, [])


    return(
        <div className="page_wrapper">
            <div className="row mt-4 dash_sm_card">
                
                <div className="col-md-4">
                    <div className="cm_card">
                        <i className="fa fa-users" />
                        <h5>Users</h5>
                        <h3 className="mb-1">{data.users}</h3>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="cm_card">
                        <i className="fa fa-picture-o" />
                        <h5>Backgrounds</h5>
                        <h3 className="mb-1">{data.backgrounds}</h3>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="cm_card">
                        <i className="fa fa-car" />
                        <h5>Cars</h5>
                        <h3 className="mb-1">{data.cars}</h3>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Dashboard;