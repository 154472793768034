import axios from 'axios';
import API from '../config/api';
import { resHandle } from '../helper';



export const luxListService = params => axios.get(API.LUX_LIST + params).then(res => {
    let { status, data } = resHandle(res);
    return {status, data};
});


export const createLuxService = params => axios.post(API.CREATE_LUX, params).then(res => {
    let { status, data } = resHandle(res);
    return {status, data};
});

export const deleteLuxService = params => axios.post(API.DELETE_LUX, params).then(res => {
    let { status, data } = resHandle(res);
    return {status, data};
});

export const updateLuxService = params => axios.post(API.UPDATE_LUX, params).then(res => {
    let { status, data } = resHandle(res);
    return {status, data};
});


