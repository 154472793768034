import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import Header from './components/templates/Header';
import Sidebar from './components/templates/Sidebar';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/resetPassword';
import Login from './pages/auth/Login';
import Cars from './pages/cars/Cars';
import Dashboard from './pages/dashboard/Dashboard';
import Users from './pages/users/Users';
import Lux from './pages/lux/Lux';
import Background from './pages/background/Background';
import Feedbacks from './pages/feedbacks/Feedbacks';
import ChangePassword from './pages/auth/changePassword';



const PrivateRoute = ({ component: Component, loggedIn,  ...rest }) => <Route {...rest} render={(props) => loggedIn ? <Component {...props} /> : <Redirect to='/login' />} />

const PublicRoute = ({ component: Component, loggedIn, ...rest }) => <Route {...rest} render={(props) => !loggedIn ? <Component {...props} /> : <Redirect to='/' />} />






const App = props => (
  <div className="site_wrapper">
    
      {props.loggedIn ? <React.Fragment><Header /><Sidebar /></React.Fragment> : ''}
      
      <Switch>
        <PublicRoute exact path='/login'                       {...props} component={ Login }  />        
        <PublicRoute exact path='/forgot-password'             {...props} component={ ForgotPassword }  />
        <PublicRoute exact path='/reset-password/:token'       {...props} component={ ResetPassword }  />             
        <PrivateRoute exact path='/'                           {...props} component={ Dashboard } />
        <PrivateRoute exact path='/users'                      {...props} component={ Users } />
        <PrivateRoute exact path='/change-password'            {...props} component={ ChangePassword} />
        <PrivateRoute exact path='/lux'                        {...props} component={ Lux } />
        <PrivateRoute exact path='/background'                 {...props} component={ Background } />
        <PrivateRoute exact path='/feedbacks'                  {...props} component={ Feedbacks } />
      </Switch>
  </div>
)

const mapStateToProps = state => {
  console.log('---------------------store start---------------------')
  console.log(state)
  console.log('---------------------store end---------------------')
  let { loggedIn } = state.auth;
  return {
    state,
    loggedIn
  }
}

export default connect(mapStateToProps)(App);