import axios from 'axios';
import API from '../config/api';
import { resHandle } from '../helper';



export const getDashboardService = () => axios.get(API.GET_DASHBOARD).then(res => {
    let { status, data } = resHandle(res);
    return {status, data};
});

export const getFeedbacksService = params => axios.get(API.GET_FEEDBACKS + params).then(res => {
    let { status, data } = resHandle(res);
    return {status, data};
});


