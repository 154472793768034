import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import moment from 'moment';
import Pagination from 'reactjs-hooks-pagination';

import Breadcrumb from '../../components/Breadcrumb';
import { userListService } from '../../services/userService';
import { Loader } from '../../components/util/Svg';
import CmPagination from '../../components/util/CmPagination';


const Users = () => {
    const [list, setList] = useState([]);
    const [loader, setLoader] = useState(true);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [search, setSearch] = useState('');
    const [filterBy, setFilterBy] = useState('');
    const [pagination, setPagination] = useState('');

    const breadcrumb = [
        {link: '', linkText: 'Users'}
    ]


    const getList = () => {
        let params = `?page=${page}&limit=${limit}&search=${search}`;
        userListService(params).then(res => {
            setLoader(false);
            if(res.status){
                setList(res.data.userList);
                setPagination({
                    currentPage: res.data.currentPage,
                    pageSize: res.data.pageSize,
                    totalPages: res.data.totalPages,
                })
            }
        })
    }



    const handlePageChange = val => {
        setPage(val)
    }


    useEffect(() => {
        getList();
    }, [page])


    return(
        <div className="page_wrapper">
            <div className="search_bar">
                <i className="fa fa-search" />
                <input
                    type="text"
                    className="form-control"
                    onChange={e => setSearch(e.target.value)}
                    value={search}
                    placeholder="Search" />
                <span className="btn btn-primary btn-sm" onClick={getList}>Search</span>
            </div>

            <Breadcrumb breadcrumb={breadcrumb} />

            <div className="d-flex justify-content-between align-content-center page_header">
                <h2>Users List</h2>
                <div>
                <Dropdown>
                    <Dropdown.Toggle className="no_btn"><i className="fa fa-sliders" /></Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">All</Dropdown.Item>
                        <Dropdown.Item href="#/action-1">Active</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">InActive</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Panding</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Canceled</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                </div>
            </div>


            <div className="table-responsive cm_card p-3 cm_table_min_height">
                {loader ? <div className="loader_lg"><Loader /></div> : ''}
                <table className="table user-table table-hover align-items-center">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Name</th>
                            <th>Created</th>
                            <th>Company Name</th>
                            <th>Company Address</th>
                            <th>Cars Created</th>
                            <th>Status</th></tr>
                    </thead>
                    <tbody>
                        {list.map((item, i) => (
                            <tr key={item._id}>
                                <td>{((i + 1) + ((page - 1) * limit))}</td>
                                <td>
                                    <Link to={`/users/${item._id}`}>
                                        <div className="d-inline-block align-middle">
                                            <span className="font-weight-bold">{item.fullName}</span>
                                            <div className="small text-gray">{item.email}</div>
                                        </div>
                                    </Link>
                                </td>
                                <td>{moment(item.created).format('DD MMM, YYYY')}</td>
                                {item.companyDetails ? <td>{item.companyDetails.companyName}</td> : item.companyName ? <td>{item.companyName}</td>:<td>-</td>}
                                {item.companyDetails ? <td>{item.companyDetails.suit}, {item.companyDetails.streetAddress}, {item.companyDetails.city}, {item.companyDetails.state}, {item.companyDetails.country} ({item.companyDetails.postalCode})</td>: <td>-</td>}
                                <td>{item.carCount}</td>
                                <td><i className="fa fa-check-circle text-success mr-2" /> {item.userStatus ? 'Active' : 'Inactive'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {list.length ? '' : <h5 className="text-center">Empty</h5>}
            </div>

            {pagination ?  <Pagination
                                totalRecords={100}
                                pageLimit={limit}
                                pageRangeDisplayed={5}
                                onChangePage={page => setPage(page)}
                            /> : ''}

        </div>
    )
}

export default Users;