import React, { useEffect, useState } from 'react';
import { Dropdown, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';

import {
    backgroundListService,
    createBackgroundService,
    deleteBackgroundService
} from '../../services/backgroundService';
import { Loader } from '../../components/util/Svg';
import Breadcrumb from '../../components/Breadcrumb';


const Background = () => {
    const [list, setList] = useState([]);
    const [loader, setLoader] = useState(true);

    const [createModal, setCreateModal] = useState(false);
    const [bgType, setBgType] = useState('');
    const [bg, setBg] = useState('');
    const [bgTypeErr, setBgTypeErr] = useState('');
    const [bgErr, setBgErr] = useState('');
    const [currentId, setCurrentId] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);


    const breadcrumb = [
        {link: '', linkText: 'Backgrounds'}
    ]


    const handleClose = () => {
        setCreateModal(false);
        setBgType('');
        setBg('');
        setBgTypeErr('');
        setBgErr('');
        setCurrentId('');

    }


    const getList = () => {
        let params = `?backgroundType=background&userType=admin`;
        backgroundListService(params).then(res => {
            setLoader(false);
            if(res.status){
                setList(res.data)
            }
        })
    }


    useEffect(() => {
        getList();
    }, [])


    const handleFileChange = e => {
        setBg(e.target.files[0])
        setBgErr('')
    }



    const handleValidate = () => {
        let validate = true;
        if(bgType == ''){
            setBgTypeErr('Background type is required');
            validate = false
        }

        if(bg == ''){
            setBgErr('Background is required');
        }

        return validate;
    }


    const handleSubmit = e => {
        e.preventDefault();
        if(handleValidate()){
            setIsDisabled(true);
            let params = new FormData();
            let query = `?backgroundType=${bgType}`
            params.append('bg', bg);


            createBackgroundService(query, params).then(res => {
                setIsDisabled(false);
                if(res.status){
                    handleClose();
                    getList();
                }
            })
        }
        
    }


    const handleDelete = backgroundId => {
        let tempList = list.filter(i => i._id != backgroundId);
        setList(tempList);
        let params =  `?backgroundId=${backgroundId}`
        deleteBackgroundService(params)
    }






    return(
        <div className="page_wrapper">
            <div className="search_bar">
                <i className="fa fa-search" />
                <input type="text" className="form-control" placeholder="Search" />
                <button className="btn btn-primary btn-sm">Search</button>
            </div>

            <Breadcrumb breadcrumb={breadcrumb} />

            <div className="d-flex justify-content-between align-content-center page_header">
                <h2>Background List</h2>
                <div className="d-flex align-items-center">
                    <span className="btn btn-primary mr-3" onClick={() => setCreateModal(true)}>Create</span>
                    <Dropdown>
                        <Dropdown.Toggle className="no_btn"><i className="fa fa-sliders" /></Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">All</Dropdown.Item>
                            <Dropdown.Item href="#/action-1">Active</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">InActive</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">Panding</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">Canceled</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>


            <div className="row position-relative cm_list_min_height">
                {
                    loader ? <div className="loader_lg"><Loader /></div> : list.map(item => (
                        <div className="col-lg-3 col-md-4 mb-4" key={item._id}>
                            <div className="cm_card bg_card p-0">
                                <img src={item.backgroundImage} />
                                {console.log(item, 'itemitemitem')}
                                <div className="p-3">
                                    <p><b>Background Type: </b> {item.backgroundType}</p>
                                    <p><b>Created By Type: </b> {item.createdByType}</p>
                                    <p><b>Created: </b> {moment(item.created).format('DD MMM, YYYY')}</p>

                                    <Dropdown alignRight className="crad_drops">
                                        <Dropdown.Toggle className="no_btn"><i className="fa fa-ellipsis-v" /></Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item href="javascript:void(0)" onClick={() => handleDelete(item._id)}>Delete</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
            



            <Modal
                show={createModal}
                onHide={handleClose}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Background</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label>Background Type</label>
                            <select
                                value={bgType}
                                onChange={e => (setBgType(e.target.value), setBgTypeErr(''))}
                                className={`form-control ${bgTypeErr ? 'is-invalid' : ''} rounded-pill`}>

                                <option selected>Choose...</option>
                                <option value="background">Background</option>
                                <option value="logo">Logo</option>
                            </select>

                                {bgTypeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{bgTypeErr}</Tooltip>}>
                                            <span className="error_tootltip" />
                                        </OverlayTrigger> : ''}
                        </div>


                        <div className="form-group">
                            <label>File</label>
                            <input
                                className={`form-control ${bgErr ? 'is-invalid' : ''} rounded-pill`}
                                type="file"
                                accept="image/x-png,image/gif,image/jpeg"
                                value=""
                                onChange={handleFileChange}
                                placeholder="Select A Background Image" />

                                {bgErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{bgErr}</Tooltip>}>
                                            <span className="error_tootltip" />
                                        </OverlayTrigger> : ''}
                        </div>

                        <div className="text-center mt-3">
                            <button disabled={isDisabled} type="submit" className="btn btn-primary rounded-pill pl-5 pr-5">Submit</button> 
                        </div>
                    </form>
                </Modal.Body>
            </Modal>


           

        </div>
    )
}

export default Background;