import axios from 'axios';
import API from '../config/api';
import { resHandle } from '../helper';



export const userListService = params => axios.get(API.USER_LIST + params).then(res => {
    let { status, data } = resHandle(res);
    return {status, data};
});


