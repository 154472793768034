import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';

import { logoutService } from '../../services/authService';
import { logoutAction } from '../../_action/authAction';

const toggleSidebar = () => document.body.classList.toggle('hide_sidebar')

const Header = props => {

    const handleLogout = () => {
        logoutService().then(res => {
            if(res.status){
                localStorage.clear();
                props.logoutAction();
            }
        })
    }

    return(
        <header className="d-flex justify-content-between align-content-center">
            <div className="mobile_trigger" onClick={toggleSidebar}><i className="fa fa-bars" /></div>
            <div className="d-flex justify-content-between align-content-center">
                <div className="notification_bell"><i className="fa fa-bell" /></div>
                <Dropdown alignRight>
                    <Dropdown.Toggle className="no_btn font-weight-bold"><img src="https://i.stack.imgur.com/l60Hf.png" alt="Avatar" className="user-avatar" />&nbsp; Hi, Admin</Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item href="javascript:void(0)">Profile</Dropdown.Item>
                        <Dropdown.Item href="/change-password">Change Password</Dropdown.Item>
                        <Dropdown.Item href="javascript:void(0)" onClick={handleLogout}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </header>
    )
}


/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    return {
        state
    };
}
  
const mapDispatchToProps = dispatch => ({
    logoutAction: () => dispatch(logoutAction()),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(Header);