export const development = {
    api: {
        url: 'https://devapi.optifo.com/api/v1/',
        mode: 'cors',
        AUTH: 'Basic b3B0aWZvX2FkbWluOm9wdGlmb0AxMjM='
    }
}


export const staging = {
    api: {
        url: 'https://stagapi.optifo.com/api/v1/',
        mode: 'cors',
        AUTH: 'Basic b3B0aWZvX2FkbWluOm9wdGlmb0AxMjM='
    }
}