export const resHandle = res => {
    let status = res.data.statusCode;
    let data;
    if (status) {
        data = res.data.responseData
    } else {
        data = res.data.error.responseMessage
    }
    return { status, data }
}


export const isColor = clr => {
    let s = new Option().style;
    s.color = clr;
    return s.color == clr;
}