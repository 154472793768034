import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import moment from 'moment';
import Pagination from 'reactjs-hooks-pagination';

import Breadcrumb from '../../components/Breadcrumb';
import { getFeedbacksService } from '../../services/otherService';
import { Loader } from '../../components/util/Svg';


const Feedbacks = () => {
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [loader, setLoader] = useState(true);
    const [infoType, setInfoType] = useState('feedback');
    const [pagination, setPagination] = useState('');
    

    const breadcrumb = [
        {link: '', linkText: 'Feedbacks'}
    ]


    const getList = () => {
        let params = `?infoType=${infoType}`;
        getFeedbacksService(params).then(res => {
            setLoader(false);
            if(res.status){
                setList(res.data.feedbacksList);
            }
        })
    }




    useEffect(() => {
        getList();
    }, [infoType])


    return(
        <div className="page_wrapper">
            <div className="search_bar">
                <i className="fa fa-search" />
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search" />
                <span className="btn btn-primary btn-sm" onClick={getList}>Search</span>
            </div>

            <Breadcrumb breadcrumb={breadcrumb} />

            <div className="d-flex justify-content-between align-content-center page_header">
                <h2>Feedbacks List</h2>
                <div className="">
                    <span onClick={() => setInfoType('feedback')} className={`btn btn-outline-dark ${infoType == 'feedback' ? 'active' : ''}`}>Feedback</span>
                    <span onClick={() => setInfoType('bug')} className={`ml-3 btn btn-outline-dark ${infoType == 'bug' ? 'active' : ''}`}>Bug</span>
                </div>
                <div>
                <Dropdown>
                    <Dropdown.Toggle className="no_btn"><i className="fa fa-sliders" /></Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">All</Dropdown.Item>
                        <Dropdown.Item href="#/action-1">Active</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">InActive</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Panding</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Canceled</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                </div>
            </div>


            <div className="table-responsive cm_card p-3 cm_table_min_height">
                {loader ? <div className="loader_lg"><Loader /></div> : ''}
                <table className="table user-table table-hover align-items-center">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Name</th>
                            <th>Created</th>
                            <th>Email</th>
                            <th>Info</th>
                            <th>Info Type</th></tr>
                    </thead>

                    <tbody>
                        {list.map((item, i) => (
                            <tr key={item._id}>
                                <td>{((i + 1) + ((page - 1) * limit))}</td>
                                <td>{item.name}</td>
                                <td>{moment(item.created).format('DD MMM, YYYY')}</td>
                                <td>{item.email}</td>
                                <td>{item.info}</td>
                                <td>{item.infoType}</td>
                                
                            </tr>
                        ))}
                    </tbody>
                </table>

                {list.length ? '' : <h5 className="text-center">Empty</h5>}
            </div>

            {pagination ?  <Pagination
                                totalRecords={100}
                                pageLimit={limit}
                                pageRangeDisplayed={5}
                                onChangePage={page => setPage(page)}
                            /> : ''}

        </div>
    )
}

export default Feedbacks;