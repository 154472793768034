import axios from 'axios';
import API from '../config/api';
import { resHandle } from '../helper';


axios.defaults.headers.common['accessToken'] = localStorage.getItem('accessToken');
axios.defaults.headers.common['Authorization'] = `${API.AUTH}`;


export const loginService = params => axios.post(API.ADMIN_LOGIN, params).then(res => {
    let { status, data } = resHandle(res);
    if(status){
        axios.defaults.headers.common['accessToken'] = `${data.accessToken}`;
        axios.defaults.headers.common['Authorization'] = `${API.AUTH}`;
    }
    return {status, data};
});



export const logoutService = () => axios.post(API.ADMIN_LOGOUT).then(res => {
    let { status, data } = resHandle(res);
    return {status, data};
});

export const forgotPassword = params => axios.post(API.FORGOT_PASSWORD, params).then(res => {
    console.log("ddddddd")
    let { status, data } = resHandle(res);
    if(status){
        axios.defaults.headers.common['Authorization'] = `${API.AUTH}`;
    }
    return {status, data};
});

export const resetPasswordService = (params) => axios.post(API.RESET_PASSWORD, params,{
    headers:{
        'accessToken': params.accessToken
    }
}).then(res => {
    console.log("dkkdddddd:::", params);
    let { status, data } = resHandle(res);
    console.log("dkkdddddd:::111", res);
    if(status){
        axios.defaults.headers.common['Authorization'] = `${API.AUTH}`;
    }
    return {status, data};
});

export const changePasswordService = params => axios.post(API.ADMIN_CHANGE_PASS, params).then(res => {
    let { status, data } = resHandle(res);
    if(status){
        axios.defaults.headers.common['accessToken'] = `${data.accessToken}`;
        axios.defaults.headers.common['Authorization'] = `${API.AUTH}`;
    }
    return {status, data};
});
