import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';

import LogoImg  from '../../assets/img/logo.svg';
import { loginService } from '../../services/authService';
import { loginAction } from '../../_action/authAction';
import { Loader } from '../../components/util/Svg';


const Login = props => {
    const [loader, setLoader] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [passwordErr, setPasswordErr] = useState('');
    const [errorMessage, setErrorMessage] = useState('');



    const handleValidate = () => {
        let validate = true;
        if(email == ''){
            setEmailErr('Email is required');
            validate = false
        }

        if(password == ''){
            setPasswordErr('Password is required');
            validate = false
        }

        return validate;
    }


    const handleSubmit = e => {
        e.preventDefault();

        if(handleValidate()){
            let params = {
                email,
                password
            }

            setLoader(true);

            loginService(params).then(res => {
                setLoader(false);
                if(res.status){
                    localStorage.setItem('accessToken', res.data.accessToken);
                    localStorage.setItem('auth', JSON.stringify(res.data.adminProfile));
                    props.loginAction(res.data.adminProfile);
                }
                else{
                    setLoader(false);
                    console.log(res.data);
                    setErrorMessage(res.data);
                }
            })
        }

    }


    return(
        <div className="d-flex justify-content-between align-items-center auth_wrapper">
            {loader ? <div className="loader_lg"><Loader /></div> : ''}
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-6 card p-5">
                        <img src={LogoImg} alt="Whoop" className="auth_logo" />
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label>Email</label>
                                    <input
                                        className={`form-control ${emailErr ? 'is-invalid' : ''} rounded-pill`}
                                        type="email"
                                        value={email}
                                        onChange={e => (setEmail(e.target.value), setEmailErr(''))}
                                        placeholder="Enter your email" />

                                        {emailErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{emailErr}</Tooltip>}>
                                                    <span className="error_tootltip" />
                                                </OverlayTrigger> : ''}
                                </div>
                                <div className="form-group">
                                    <label>Password</label>
                                    <input
                                        className={`form-control ${passwordErr ? 'is-invalid' : ''} rounded-pill`}
                                        type="password"
                                        value={password}
                                        onChange={e => (setPassword(e.target.value), setPasswordErr(''))}
                                        placeholder="Enter your password" />
                                    
                                    {passwordErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{passwordErr}</Tooltip>}>
                                                <span className="error_tootltip" />
                                            </OverlayTrigger> : ''}

                                    <small>
                                        <Link to="/forgot-password">Forgot password?</Link>
                                    </small>
                                </div>

                                <div className="text-center mt-3">
                                {errorMessage && <div className="error"> <h6>{errorMessage}</h6> </div>}
                                    <button type="submit" className="btn btn-primary rounded-pill pl-5 pr-5">Login</button> 
                                </div>
                            </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    return {
        state
    };
}
  
const mapDispatchToProps = dispatch => ({
    loginAction: params => dispatch(loginAction(params)),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(Login);